<template>
    <div class="home">
        <location :locationArr="locationArr"/>
        <div class="titleTap">
            <h1>友情<span>链接</span></h1>
            <div></div>
        </div>

       <!-- <div class="enterpriseBox">
            <div class="enterpriseLogo" @click="skipUrl(item.linkUrl)" v-for="(item,index) in resData.records"
                 :key="index">
                <div class="LogoBox">
                    <img :src="item.imgUrl">
                </div>
                <p>{{item.linkName}}</p>
            </div>
        </div> -->
     <div class="regulationBoxOne">
            <div class="regulationBox" @click="skipUrl(item.linkUrl)" v-for="(item,index) in resData.records"
                 :key="index">
                <div class="regulationPar">
                    <div>
                        <div class="blueQiu"></div>
                        <p class="parTitle">{{item.linkName}}</p>
                    </div>

                </div>
            </div>
        </div>
		
        <el-pagination style="text-align: right;margin: 60px 0;"
                       background
                       prev-text="上一页"
                       next-text="下一页"
                       layout="prev, pager, next"
                       @current-change="careChange"
                       :page-size=12
                       :total="resData.total">
        </el-pagination>
    </div>
</template>

<script>
    import location from '@/components/location.vue'

    export default {
        name: 'linkList',
        components: {
            location
        },
        data() {
            return {
                resData: {},
                page: 0,
                locationArr: [{"title": "友情链接", "url": "/link"}]
            }
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile/link"
			        });
			}
		},
        mounted() {
            this.getInfoData()
        },
        methods: {
            careChange(val) {
                this.page = val
                this.getInfoData()
            },
            skipUrl(url) {
                window.open(url)
            },
            getInfoData() {
                let _this = this
                this.$http.yqljList({page: _this.page}).then(res => {
                    if (res.code == 200) {
                        this.resData = res.data
                    }
                })
            }
        }
    }
</script>
<style type="text/css">
@import '~@/assets/css/regulation.css';

    .home {
        width: 1200px;
        margin: 0 auto;
    }

    .titleTap {
        text-align: left;
    }

    .titleTap > h1 {
        font-size: 28px;
        margin-top: 56px;
        color: #005bac;
    }

    .titleTap > h1 > span {
        color: #000000;
    }

    .titleTap > div {
        width: 80px;
        height: 4px;
        background-color: #005bac;
        margin: 16px 0 74px 0;
    }

    /* .titleTap>p{
        font-size: 14px;
        color: #b3b6b5;
        margin-bottom: 53px;
    } */
   /* .enterpriseLogo > p {
        text-align: center;
    } */
</style>
